import { push } from 'connected-react-router';
import { sessionService } from 'redux-react-session';

import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from 'app-state/actions/authentication';
import { changeOrdersFilterParam } from 'app-state/actions/orders';
import { removeRedirectPath } from 'app-state/actions/shared';
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN,
  LOGOUT,
  OAUTH2_LOGIN,
  OAUTH2_LOGOUT,
  RESET_PASSWORD,
  SAVE_USER_DATA,
  SIGN_UP,
  TWO_FACTOR_AUTH_CONFIRM,
  TWO_FACTOR_AUTH_DISABLE,
  TWO_FACTOR_AUTH_GET_CODE,
} from 'app-state/constants';
import { getRedirectPath, getUser } from 'app-state/selectors/authentication';

import {
  PLAID_ACCESS_TOKEN_STORAGE_KEY,
  PLAID_LINK_TOKEN_STORAGE_KEY,
} from 'modules/plaid/constants';
import API from 'constants/api';
import Routes from 'constants/routes';
import { FeatureFlag, useFeatureFlag } from 'providers';
import getAlternativePath from 'helpers/get-alternative-redirect-path';
import gtmTrack from 'shared-parts/helpers/gtm-track';
import request from 'shared-parts/helpers/request';

export function* saveUserDataSaga({ user, redirectTo }) {
  yield call(sessionService.saveSession, { token: user.authToken || true });
  yield call(sessionService.saveUser, user);

  if (redirectTo) {
    yield put(push(redirectTo));
    yield put(removeRedirectPath());
  }
}

export function* saveUserDataWatcher() {
  yield takeEvery(SAVE_USER_DATA, saveUserDataSaga);
}

export function* signUp({ newEmail, password, referrerCode, actionToken, isNewEmailVisible }) {
  try {
    const signUpParams = {
      newEmail,
      password,
      referrerCode,
      actionToken,
    };

    const signUpApi = isNewEmailVisible ? API.signUp() : API.signUpInvited();
    const { data } = yield call(request, signUpApi, 'POST', signUpParams);

    if (data.loginStatus === 'confirmed') {
      yield call(saveUserDataSaga, actions.saveUserData(data));
    }

    yield put(actions.signUpSuccess({ email: newEmail, loginStatus: data.loginStatus }));

    yield localStorage.removeItem('actionToken');
    yield localStorage.removeItem('referrer');
    const gtmEventData = {
      referrer: referrerCode,
    };
    const user = {
      email: isNewEmailVisible ? newEmail : data.newEmail,
      id: data.id,
    };
    yield gtmTrack(gtmEventData, 'GCUserSignUp', user);
  } catch (e) {
    yield put(actions.signUpError(e.response.details));
  }
}

export function* signUpWatcher() {
  yield takeEvery(SIGN_UP, signUp);
}

export function* login({
  params,
  setSubmitting,
  setErrors,
  isEmailVisible,
  setIsTwoFactorAuth,
  isAgentPermissionsEnabled,
}) {
  try {
    const extendedParams = {
      actionToken: String(localStorage.getItem('actionToken')),
      ...params,
    };
    const signUpApi = isEmailVisible ? API.login() : API.loginInvited();
    const { data } = yield call(request, signUpApi, 'POST', extendedParams);
    const { investor, onboardingStatus } = data;
    const { onboardingPhase, preferenceSet } = investor || {};
    setSubmitting(false);
    yield localStorage.removeItem('actionToken');
    const redirectTo = yield select(getRedirectPath);
    const alternativePath = getAlternativePath(
      isAgentPermissionsEnabled,
      onboardingStatus,
      onboardingPhase,
      preferenceSet,
    );
    yield put(actions.saveUserData(data, redirectTo || alternativePath));
    yield put(actions.loginSuccess(data));
    yield gtmTrack({}, 'GCUserSignIn', data);
  } catch (e) {
    const errorObject = yield e.status === 403
      ? { password: ['Your account has been locked'] }
      : e.response.details;

    if (e.status === 400 && errorObject.otpEnabled) {
      setIsTwoFactorAuth(true);
    }

    if (
      errorObject.password &&
      Array.isArray(errorObject.password) &&
      errorObject.password.includes('Incorrect Email or Password')
    ) {
      if (errorObject.email && Array.isArray(errorObject.email)) {
        errorObject.email.push(' ');
      } else {
        errorObject.email = [' '];
      }
    }

    setSubmitting(false);
    setErrors(errorObject);
  }
}

function* oauth2Login({ setErrors }) {
  try {
    // TODO: work in the action token
    const { data } = yield call(request, API.currentUser(), 'GET');

    const { investor, onboardingStatus } = data;
    const { onboardingPhase, preferenceSet } = investor || {};

    const redirectTo = yield select(getRedirectPath);
    const alternativePath = getAlternativePath(onboardingStatus, onboardingPhase, preferenceSet);

    yield put(actions.saveUserData(data, redirectTo || alternativePath));
    yield put(actions.loginSuccess(data));
    yield gtmTrack({}, 'GCUserSignIn', data);
  } catch (e) {
    const errorObject = yield e.status === 403
      ? { password: ['Your account has been locked'] }
      : e.response.details;

    setErrors(errorObject);
  }
}

export function* loginWatcher() {
  yield takeEvery(LOGIN, login);
}

export function* oauth2LoginWatcher() {
  yield takeEvery(OAUTH2_LOGIN, oauth2Login);
}

export function* logout({ isSessionExpired, redirectTo = Routes.Login(), shouldRedirect = true }) {
  try {
    if (!isSessionExpired) {
      yield call(request, API.logout(), 'DELETE');
    }
    yield call(sessionService.deleteUser);
    yield call(sessionService.deleteSession);
    yield put(actions.logoutSuccess());

    localStorage.removeItem('ordersFilterParam');
    localStorage.removeItem('actionPath');
    localStorage.removeItem(PLAID_ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(PLAID_LINK_TOKEN_STORAGE_KEY);

    yield put(changeOrdersFilterParam('ALL'));
    if (shouldRedirect) {
      yield put(push(redirectTo));
    }
  } catch (e) {
    yield put(actions.logoutError(e));
  }
}

function* oauth2Logout() {
  try {
    yield put(push('/signout'));

    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const oauth2LogoutRedirectUrl = `${window.config.OAUTH2_LOGOUT_REDIRECT_URL}${
      window.config.OAUTH2_LOGOUT_APPEND_BASE_URL === true ||
      window.config.OAUTH2_LOGOUT_APPEND_BASE_URL === 'true'
        ? encodeURIComponent(baseUrl)
        : ''
    }`;
    const oauth2Url = `${baseUrl}/oauth2/sign_out?rd=${encodeURIComponent(
      oauth2LogoutRedirectUrl,
    )}`;

    localStorage.removeItem('ordersFilterParam');
    localStorage.removeItem('actionPath');
    localStorage.removeItem(PLAID_ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(PLAID_LINK_TOKEN_STORAGE_KEY);

    yield put(changeOrdersFilterParam('ALL'));

    yield call(sessionService.deleteSession);
    yield call(sessionService.deleteUser);

    window.location.replace(oauth2Url);
  } catch (e) {
    yield put(actions.logoutError(e));
  }
}

export function* logoutWatcher() {
  yield takeEvery(LOGOUT, logout);
}

export function* oauth2LogoutWatcher() {
  yield takeEvery(OAUTH2_LOGOUT, oauth2Logout);
}

export function* resetPassword({
  setSubmitting,
  setErrors,
  formData,
  token,
  isEmailVisible,
  setIsTwoFactorAuth,
  isAgentPermissionsEnabled,
}) {
  try {
    const resetData = yield { ...formData, token };
    const { data } = yield call(request, API.resetPassword(), 'PUT', resetData);
    setSubmitting(false);
    const loginParams = yield {
      email: data.email,
      password: formData.password,
    };

    if (data.authCode) loginParams.otpCode = data.authCode;

    if (useFeatureFlag(FeatureFlag.NEW_USER_REGISTRATION_FLOW_ENABLED)) {
      yield put(push(Routes.Login()));
    } else {
      yield put(
        actions.login({
          params: loginParams,
          setSubmitting,
          setErrors,
          isEmailVisible,
          isAgentPermissionsEnabled,
        }),
      );
    }
  } catch (e) {
    const errorDetails = yield e.response.details;

    const getErrorKey = () => {
      if (formData.recoveryCode) return 'recoveryCode';
      if (formData.otpCode) return 'otpCode';

      return 'password';
    };

    setSubmitting(false);

    if (e.status === 400 && errorDetails.otpEnabled) {
      setIsTwoFactorAuth(true);
    } else {
      const errorObject = {
        [getErrorKey()]:
          e.status === 403
            ? ['Your account has been locked']
            : errorDetails.recoveryCode ||
              errorDetails.otpCode ||
              errorDetails.token ||
              errorDetails.password,
      };

      setErrors(errorObject);
    }
  }
}

export function* resetPasswordWatcher() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* forgotPassword({ setSubmitting, setErrors, form, showEmailSentText }) {
  try {
    yield call(request, API.forgotPassword(), 'POST', form);
    setSubmitting(false);
    showEmailSentText();
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

export function* forgotPasswordWatcher() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* changePassword({ form, handleSuccess, setSubmitting, setErrors }) {
  try {
    yield call(request, API.changePassword(), 'PUT', form);
    setSubmitting(false);
    handleSuccess();
  } catch (e) {
    setSubmitting(false);
    if (e.response.details) setErrors(e.response.details);
  }
}

export function* changePasswordWatcher() {
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

export function* twoFactorAuthGetCode() {
  try {
    yield put(actions.twoFactorAuthCodeClear());
    const { data } = yield call(request, API.TwoFactorAuthentication());
    yield put(actions.twoFactorAuthGetCodeSuccess(data));
  } catch (e) {
    yield put(actions.twoFactorAuthGetCodeError(e.response.details));
  }
}

export function* twoFactorAuthGetCodeWatcher() {
  yield takeLatest(TWO_FACTOR_AUTH_GET_CODE, twoFactorAuthGetCode);
}

export function* twoFactorAuthConfirm({
  data,
  setSubmitting,
  setErrors,
  showTwoFactorAuthBackupCodesModal,
}) {
  try {
    const {
      data: { recoveryCodes },
    } = yield call(request, API.TwoFactorAuthentication(), 'POST', data, {
      ignoredErrorCodes: [403],
    });
    yield put(actions.twoFactorAuthCodeClear());
    const user = yield select(getUser);
    yield call(
      saveUserDataSaga,
      actions.saveUserData({
        ...user,
        twoFactorAuthEnabled: true,
      }),
    );
    showTwoFactorAuthBackupCodesModal(recoveryCodes);
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

export function* twoFactorAuthConfirmWatcher() {
  yield takeEvery(TWO_FACTOR_AUTH_CONFIRM, twoFactorAuthConfirm);
}

export function* twoFactorAuthDisable({ setSubmitting, setErrors }) {
  try {
    yield call(request, API.TwoFactorAuthentication(), 'DELETE');
    const user = yield select(getUser);
    yield call(
      saveUserDataSaga,
      actions.saveUserData({
        ...user,
        twoFactorAuthEnabled: false,
      }),
    );
  } catch (e) {
    setSubmitting(false);
    setErrors(e.response.details);
  }
}

export function* twoFactorAuthDisableWatcher() {
  yield takeEvery(TWO_FACTOR_AUTH_DISABLE, twoFactorAuthDisable);
}
